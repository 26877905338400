import React, {useState} from 'react'
import useScript from 'react-script-hook';
import paymentStyles from "../../styles/checkout/payment.module.scss"

const SatispayPayment = ({handleSubmitOrder, data_, disabled}) => {
    const [loading, error] = useScript({ src: process.env.GATSBY_SATISPAY_SCRIPT, checkForExisting: true});
    const [error1, setError1] = useState(null);
    /*global SatispayWebButton*/ //

    const onClickHandler = async (e) => {
        e.preventDefault()
      
        const response = await fetch(`${process.env.GATSBY_NETLIFY_SATISPAY_URL}`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({totale: data_.totale_da_pagare})
          })

          const paymentId = await response.text()

          if (response.ok) {
            const satispay = !loading && SatispayWebButton.configure({
            paymentId,
            completed: async function() {
              const paymentDetails = await fetch(`${process.env.GATSBY_NETLIFY_SATISPAY_URL}?payment_id=${paymentId}`)
              const paymentStatus = await paymentDetails.text()

              if (paymentStatus === 'ACCEPTED') {
                  satispay.close()
                  handleSubmitOrder(e,'satispay')
                  return
                } else {
                  setError1('fail')
                  return
                }
            }
        })
        satispay.open()
        }
    }
 
    if (error1) {
        return  <p className={paymentStyles.alertRicevuta}>Siamo spiacenti, il tuo pagamento con Satispay non è stato completato... ricarica la pagina e riprova, se il problema persiste contattaci direttamente su whatsapp o via email</p>
    }

    return (
        <button className={paymentStyles.btnSat} onClick={(e)=> onClickHandler(e)} disabled={disabled}><img src="https://online.satispay.com/images/it-pay-red.svg" 
        alt="paga con satispay"  style={{height: 50}} /> </button>
    )
}

export default SatispayPayment 